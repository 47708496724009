@font-face {
    font-family: 'Work Sans';
    src: url(../assets/fonts/work-sans/WorkSans-Light.otf);
    font-weight: 300;
}

@font-face {
    font-family: 'Work Sans';
    src: url(../assets/fonts/work-sans/WorkSans-Regular.otf);
}

@font-face {
    font-family: 'Work Sans';
    src: url(../assets/fonts/work-sans/WorkSans-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: 'Work Sans';
    src: url(../assets/fonts/work-sans/WorkSans-SemiBold.otf);
    font-weight: 600;
}

@font-face {
    font-family: 'Work Sans';
    src: url(../assets/fonts/work-sans/WorkSans-Bold.otf);
    font-weight: 700;
}


@font-face {
    font-family: 'IBM Plex Sans';
    src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf);
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url(../assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Hind';
    src: url(../assets/fonts/Hind/Hind-Regular.ttf);
}

@font-face {
    font-family: 'Hind';
    src: url(../assets/fonts/Hind/Hind-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Hind';
    src: url(../assets/fonts/Hind/Hind-SemiBold.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Hind';
    src: url(../assets/fonts/Hind/Hind-Bold.ttf);
    font-weight: 700;
}