#cv-pdf {
    background-color: #fff;
    color: #000;
}

.leftbar {
    background-color: hsl(204deg 6% 15%);
    min-width: 170px;
    font-family: 'Euclid Circular A';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-left: 40px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
}

.rightbar {
    flex: 1;
    padding: 0px 25px;
}

#designation {
    padding-top: 40px;
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.8px;
    color: #212121;
    mix-blend-mode: normal;
    opacity: 0.6;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#name {

    margin-top: 8px;
    margin-bottom: 25px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    color: #212121;
}

.padding-vertical {
    padding-top: 27px;
    padding-bottom: 27px;
}

.contact-inline {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: -0.2px;

    color: #212121;
    mix-blend-mode: normal;
    opacity: 0.6;
}

#description {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: #212121;
    mix-blend-mode: normal;
    opacity: 0.6;
}

.datetext {

    /* Style 1 / Year-City */

    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 180% */


    /* Colors / White */
    color: rgba(255, 255, 255, 0.5);
}

.degree {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #212121;
}

.institude-location {
    font-family: 'Hind';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    /* identical to box height, or 173% */

    letter-spacing: -0.2px;

    /* Colors / Black */

    color: #212121;
}

.skill-value {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #212121;

}