@import url("./fonts.css");

.title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #efefef;
    margin-top: 20px;
}

.body {

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #cacaca;
}



.arrowbtn {
    background-color: rgba(50, 50, 50, 0.6);
    padding: 0.625rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 6.25rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-self: center;
    justify-self: center;
    display: flex;
    border: 0.0625rem solid #acacac;
}

.arrowbtn:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.tagbox {
    background-color: #000;
    padding: 0.4375rem 0.75rem;
    border-radius: 0.375rem;
    color: #F0F1F3;
    margin-right: 0.1875rem;
    font-size: 0.8125rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin-top: 0.1875rem;
}